<div class="page-section">
  <div class="section-content">
    <div class="card">
      <h1>Contact</h1>
      <p>
        Reach out with any questions, or to start a discussion about a project
      </p>

      @if (submitted) {
      <p class="success-message">
        Your contact request has been submitted, you should hear back soon.
      </p>
      } @else {
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <formly-form
          [form]="form"
          [fields]="fields"
          [model]="model"
        ></formly-form>
        <button type="submit" color="primary" mat-flat-button>Submit</button>
      </form>
      }

      <h5>Or, get in touch directly</h5>
      <div class="direct-contact-details">
        <div class="detail">
          <h2>Email</h2>
          <a href="mailto:contact@smithsolutions.io">
            <p>contact&#64;smithsolutions.io</p>
          </a>
        </div>

        <div class="detail">
          <h2>Phone</h2>
          <a href="tel:+19792021930"
            ><p>+1&nbsp;(979)&nbsp;202&#8209;1930</p></a
          >
        </div>
      </div>
    </div>
  </div>
</div>
