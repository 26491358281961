import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, Inject, PLATFORM_ID } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  CookieManagerComponent,
  MarketingAppBarComponent,
  MarketingFooterComponent,
} from '@sss/angular';

import { isPlatformBrowser } from '@angular/common';
import * as Sentry from '@sentry/angular';

const APP_VERSION = '1.0.4';

@Component({
  standalone: true,
  imports: [
    RouterModule,
    CookieManagerComponent,
    MarketingAppBarComponent,
    MarketingFooterComponent,
  ],
  selector: 'sss-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements AfterViewInit {
  constructor(
    @Inject(PLATFORM_ID) platformId: any,
    private readonly http: HttpClient
  ) {
    if (isPlatformBrowser(platformId)) {
      try {
        this.http
          .get('https://api.ipify.org?format=json')
          .subscribe((res: any) => {
            Sentry.setUser({
              ip_address: res.ip,
            });
          });
      } catch (e) {
        console.log('Failed to track user IP');
      }
    }
  }

  ngAfterViewInit() {
    console.log('APP VERSION:', APP_VERSION);
  }
}
