<div class="page-section">
  <div class="section-content">
    <a href="/"
      ><img class="brand" src="assets/images/marketing-app-bar-logo.png" />
    </a>

    <div class="links">
      @for (link of links; track $index) {
      <a [href]="link.path">{{ link.label }}</a>
      }
    </div>
  </div>
</div>
