import { Component } from '@angular/core';

@Component({
  selector: 'sss-full-stack-section',
  standalone: true,
  imports: [],
  templateUrl: './full-stack-section.component.html',
  styleUrl: './full-stack-section.component.scss',
})
export class FullStackSectionComponent {}
