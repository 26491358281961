<div class="page-section">
  <div class="section-content">
    <h1>Full Stack Software Solutions</h1>

    <h2>Powerful Apps for First-Class Business</h2>
    <p>
      Dynamic and capable software from start to finish covering all aspects of
      the process from planning & designing to developing & deploying
    </p>

    <img src="assets/images/full-stack-graphic.png" />
  </div>
</div>
