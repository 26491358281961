<div class="page-section">
  <div class="section-content">
    <div class="details">
      <div class="detail">
        <h4>Legal</h4>
        <a href="/terms-of-service">Terms of Service</a>
        <a href="/privacy-policy">Privacy Policy</a>
        <a href="/cookie-policy">Cookie Policy</a>
      </div>

      <div class="detail contact">
        <h4>Contact</h4>
        <a href="mailto:contact@smithsolutions.io">
          <p>contact&#64;smithsolutions.io</p>
        </a>
        <a href="tel:+19792021930"><p>+1&nbsp;(979)&nbsp;202&#8209;1930</p></a>
      </div>
    </div>

    <p>&copy; 2024 Smith Software Solutions</p>
  </div>
</div>
