import { Component } from '@angular/core';

@Component({
  selector: 'sss-standalone-services-section',
  standalone: true,
  imports: [],
  templateUrl: './standalone-services-section.component.html',
  styleUrl: './standalone-services-section.component.scss',
})
export class StandaloneServicesSectionComponent {}
