import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

const consentKey = 'sss-cookie-consent';

@Component({
  selector: 'sss-cookie-manager',
  standalone: true,
  imports: [MatButton, MatIconModule],
  templateUrl: './cookie-manager.component.html',
  styleUrl: './cookie-manager.component.scss',
})
export class CookieManagerComponent implements OnInit {
  expanded = false;
  consented = false;

  constructor(@Inject(PLATFORM_ID) private platformId: any) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      const value = localStorage.getItem(consentKey);
      this.consented = value == 'true';
      this.expanded = !value;
      this.updateGAConsent();
    }
  }

  toggleExpanded() {
    this.expanded = !this.expanded;
  }

  giveConsent() {
    this.setConsent(true);
    this.expanded = false;
    this.updateGAConsent();
  }

  revokeConsent() {
    this.setConsent(false);
    this.expanded = false;
    this.updateGAConsent();
  }

  setConsent(value: boolean) {
    if (isPlatformBrowser(this.platformId))
      localStorage.setItem(consentKey, `${value}`);

    this.consented = value;
  }

  updateGAConsent() {
    if (!document.getElementById('gtag-script')) {
      try {
        const gTagKey = '1XX9L1TTY2';
        const url = 'https://www.googletagmanager.com/gtag/js?id=';
        const gTagScript = document.createElement('script');
        gTagScript.id = 'gtag-script';
        gTagScript.async = true;
        gTagScript.src = `${url}G-${gTagKey}`;
        document.head.appendChild(gTagScript);

        const dataLayerScript = document.createElement('script');
        dataLayerScript.innerHTML = `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-${gTagKey}', {'send_page_view': false});
          gtag('config', 'AW-16598390783');
          gtag('consent', 'update', {
            ad_storage: 'denied',
            analytics_storage: 'denied',
          });
          `;
        document.head.appendChild(dataLayerScript);
      } catch (e) {
        console.error('Error adding Google Analytics', e);
      }
    }

    if (this.consented) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      gtag('consent', 'update', {
        ad_storage: 'granted',
        analytics_storage: 'granted',
      });
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    else if (document.getElementById('gtag-script') && gtag) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      gtag('consent', 'update', {
        ad_storage: 'denied',
        analytics_storage: 'denied',
      });
    }
  }
}
