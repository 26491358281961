import { Component } from '@angular/core';

@Component({
  selector: 'sss-cookie-policy',
  standalone: true,
  imports: [],
  templateUrl: './cookie-policy.component.html',
  styleUrl: './cookie-policy.component.scss',
})
export class CookiePolicyComponent {}
