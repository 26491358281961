@if (expanded) {
<div class="container">
  <h3>Cookie Notice</h3>
  <p>
    We use cookies to enhance your browsing experience, and analyze our traffic.
    By clicking "Accept All", you consent to our use of cookies. Visit our
    Cookie Policy to
    <a href="/cookie-policy">Learn more</a>.
  </p>
  <div class="actions">
    <button mat-flat-button color="primary" (click)="revokeConsent()">
      Decline All
    </button>
    <button mat-flat-button color="primary" (click)="giveConsent()">
      Accept All
    </button>
  </div>
</div>
} @if (!expanded) {
<div class="preview" (click)="toggleExpanded()">
  <mat-icon> cookie</mat-icon>
</div>
}
