<div class="page-section">
  <div class="section-content">
    <!-- frontend services -->
    <div class="service-side frontend">
      <h1>Standalone Frontend Services</h1>
      <p>
        Beautifully designed front-ends with responsive UI/UX and eye catching
        graphics
      </p>

      <img src="assets/images/standalone-frontend-services-graphic.png" />
    </div>

    <!-- backend services -->
    <div class="service-side backend">
      <h1>Standalone Backend Services</h1>
      <p>
        Modern, scalable, and fast backend services tailored to your business
        and data needs
      </p>

      <img src="assets/images/standalone-backend-services-graphic.png" />
    </div>
  </div>
</div>
