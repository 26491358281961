import { Component } from '@angular/core';

@Component({
  selector: 'sss-marketing-footer',
  standalone: true,
  imports: [],
  templateUrl: './marketing-footer.component.html',
  styleUrl: './marketing-footer.component.scss',
})
export class MarketingFooterComponent {}
