import { Route } from '@angular/router';
import {
  CookiePolicyComponent,
  LandingViewComponent,
  PrivacyPolicyComponent,
  TermsOfServiceComponent,
} from '@sss/angular';

export const appRoutes: Route[] = [
  {
    path: '',
    component: LandingViewComponent,
  },
  {
    path: 'cookie-policy',
    component: CookiePolicyComponent,
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'terms-of-service',
    component: TermsOfServiceComponent,
  },
];
