import { Component } from '@angular/core';
import { HeroSectionComponent } from '../../components/hero-section/hero-section.component';
import { FullStackSectionComponent } from '../../components/full-stack-section/full-stack-section.component';
import { StandaloneServicesSectionComponent } from '../../components/standalone-services-section/standalone-services-section.component';
import { ContactSectionComponent } from '../../components/contact-section/contact-section.component';

@Component({
  selector: 'sss-landing-view',
  standalone: true,
  imports: [
    HeroSectionComponent,
    FullStackSectionComponent,
    StandaloneServicesSectionComponent,
    ContactSectionComponent,
  ],
  templateUrl: './landing-view.component.html',
  styleUrl: './landing-view.component.scss',
})
export class LandingViewComponent {}
