import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { lastValueFrom, tap } from 'rxjs';
import { environment } from '../../../_misc/environments/environment';
import { formatPhoneNumber } from './../../../../../../ts/src/lib/web-input/format-phone-number';

@Component({
  selector: 'sss-contact-section',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormlyModule,
    MatCheckboxModule,
    MatButtonModule,
  ],
  templateUrl: './contact-section.component.html',
  styleUrl: './contact-section.component.scss',
})
export class ContactSectionComponent {
  form = new FormGroup({});
  model = {} as any;
  fields: FormlyFieldConfig[] = [
    {
      key: 'name',
      type: 'input',
      props: {
        label: 'Name',
        required: true,
      },
    },
    {
      key: 'email',
      type: 'input',
      props: {
        label: 'Email',
        required: true,
      },
    },
    {
      key: 'phone',
      type: 'input',
      props: {
        label: 'Phone',
      },
      hooks: {
        onInit: (field) => {
          return field.formControl?.valueChanges.pipe(
            tap((phoneNumber) => {
              const copyOfModel = { ...this.model };
              copyOfModel.phone = formatPhoneNumber(phoneNumber);
              this.model = copyOfModel;
            })
          );
        },
      },
    },
    {
      key: 'notes',
      type: 'textarea',
      props: {
        label: 'Notes',
        rows: 5,
        placeholder: 'What can I help you with?',
        required: true,
      },
    },
    {
      key: 'termsOfServiceAgreement',
      type: 'checkbox',
      defaultValue: false,
      validators: {
        agreed: {
          expression: (control: AbstractControl) => control.value == true,
          message: () => 'This must be confirmed.',
        },
      },
      // template:
      //   '<input type="checkbox" [formControl]="formControl" [formlyAttributes]="field"/>I certify that I am at least 18 years old and that I agree to the <a href="terms-of-service">Terms of Service</a> and <a href="/privacy-policy">Privacy Policy</a>.',
      props: {
        label:
          'I certify that I am at least 18 years old and that I agree to the Terms of Service and Privacy Policy.',
        required: true,
      },
    },
  ];

  submitted = false;

  constructor(private httpClient: HttpClient) {}

  async onSubmit() {
    if (this.form.valid) {
      this.submitted = true;
      const submission = { ...this.model };
      delete submission.termsOfServiceAgreement;
      const res = await lastValueFrom(
        this.httpClient.post(
          `${environment.functionService}/marketing/contact`,
          submission
        )
      );

      console.log('Contact Form Submission Response:', res);

      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (gtag) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          gtag('event', 'conversion', {
            send_to: 'AW-16598390783/_NHgCLGm5rcZEP-v3eo9',
          });
        }
      } catch (e) {
        console.error('Failed to Log Ad Conversion', e, this.form);
      }
    } else {
      console.error('Form is Invalid', this.form);
    }
  }
}
