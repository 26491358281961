import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'sss-marketing-app-bar',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './marketing-app-bar.component.html',
  styleUrl: './marketing-app-bar.component.scss',
})
export class MarketingAppBarComponent {
  links: { label: string; path: string }[] = [
    {
      label: 'About',
      path: '#about',
    },
    {
      label: 'Services',
      path: '#services',
    },
    {
      label: 'Contact',
      path: '#contact',
    },
  ];
}
